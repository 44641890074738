import { AsyncPipe, NgFor, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Subject } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
type validationCheckStatus = 'valid' | 'invalid' | 'default';

/**
 * This component will be shared across the application, for the purpose of indicating
 * the validity of a field. What does this mean? When we need to have a couple of checks
 * for a given field, we don't need to repeat the same UI over and over, we just pass in
 * the required data, and this component will render the validation checks. For example,
 * we can use it in:
 *
 * 1. Password validation.
 * 2. Email validation.
 * 3. Phone number validation.
 */

export interface FieldValidationCheck {
  validationCheck: string;
  validationCheckStatus: validationCheckStatus;
}

@Component({
  selector: 'app-field-validator-indicator',
  styleUrls: ['field-validation-indicator.component.scss'],
  templateUrl: 'field-validation-indicator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, NgTemplateOutlet, AsyncPipe],
})
export class FieldValidatorIndicatorComponent {
  @Input() scopeAssetsPath: string;

  @Input() public checks$: Subject<Array<FieldValidationCheck>> = new Subject<
    Array<FieldValidationCheck>
  >();

  public getIconURL(statusCheck: validationCheckStatus): string {
    switch (statusCheck) {
      case 'valid':
        return 'check-mark-valid.svg';
      case 'invalid':
        return 'check-mark-invalid.svg';
      default:
        return 'check-mark-default.svg';
    }
  }
}
