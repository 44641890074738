<div class="field-validation-indicator">
  <div
    *ngFor="let check of checks$ | async"
    class="field-validation-indicator__item body2--regular"
  >
    <ng-template
      [ngTemplateOutlet]="iconTemplate"
      [ngTemplateOutletContext]="{
        status: check.validationCheckStatus
      }"
    ></ng-template>
    <ng-template
      [ngTemplateOutlet]="textTemplate"
      [ngTemplateOutletContext]="{
        text: check.validationCheck,
        status: check.validationCheckStatus
      }"
    ></ng-template>
  </div>
</div>

<ng-template #textTemplate let-text="text" let-status="status">
  <div class="field-validation-indicator__item__text {{ status }}">
    {{ text }}
  </div>
</ng-template>

<ng-template #iconTemplate let-status="status">
  <img loading="lazy" src="{{ scopeAssetsPath + getIconURL(status) }}" alt="{{ status }}" />
</ng-template>
