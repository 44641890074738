import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { provideRouter } from '@angular/router';
import {
  RecaptchaModule,
  RecaptchaSettings,
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_SETTINGS,
} from 'ng-recaptcha';
import { FieldValidatorIndicatorComponent } from '../../shared/components/field-validation-indicator/field-validation-indicator.component';
import { SignupDetailsComponent } from './components/signup-details/signup-details.component';
import { SignupLandingComponent } from './components/signup-landing/signup-landing.component';
import { SignupOTPComponent } from './components/signup-otp/signup-otp.component';
import { SignupStepperComponent } from './components/signup-stepper/signup-stepper.component';
import { SignupStepsBaseComponent } from './components/signup-steps-base.component';
import { SignupComponent } from './signup.component';

import { environment } from '../../../../environments/environment';

const SIGNUP_STEPS_COMPONENT = [SignupLandingComponent, SignupOTPComponent, SignupDetailsComponent];

const SIGNUP_COMPONENTS = [SignupComponent, SignupStepperComponent, SignupStepsBaseComponent];

const PIPES: [] = [];

const IMPORTED_SHARED_COMPONENTS = [FieldValidatorIndicatorComponent];

const RECAPTCHA_SITE_KEY: RecaptchaSettings = {
  siteKey: environment.RECAPTCHA_SITE_KEY,
};

@NgModule({
  imports: [
    RecaptchaModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatIconModule,
    ...SIGNUP_COMPONENTS,
    ...SIGNUP_STEPS_COMPONENT,
    ...PIPES,
    ...IMPORTED_SHARED_COMPONENTS,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: RECAPTCHA_SITE_KEY,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      // TODO Set up this dynamically
      useValue: 'ar',
    },
    provideRouter([
      {
        path: '',
        component: SignupComponent,
      },
    ]),
  ],
})
export class SignupModule {}
