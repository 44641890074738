import Botpoison from '@botpoison/browser';

export async function getBotPoisonChallenge(): Promise<string> {
  const botpoison = new Botpoison({
    // @TODO: Public key should be removed from here and moved to the environment variables
    publicKey: 'pk_477112b3-506d-4e49-8981-c30d47d4ee9f',
  });

  const { solution } = await botpoison.challenge();

  return solution;
}
