import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { CurrentUserStep } from 'src/app/presentation/shared/components/shared-stepper-indicator/interfaces';
import { SignupDetailsComponent } from '../signup-details/signup-details.component';
import { SignupLandingComponent } from '../signup-landing/signup-landing.component';
import { SignupOTPComponent } from '../signup-otp/signup-otp.component';

type SignUpSteps = Extract<
  CurrentUserStep,
  'signupLanding' | 'signupOtp' | 'signupDetails' | 'signupFullyVerified'
>;

@Component({
  selector: 'app-signup-stepper',
  styleUrls: ['signup-stepper.component.scss'],
  templateUrl: 'signup-stepper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SignupStepperComponent implements OnInit, OnDestroy {
  @Input() public currentUserStep$: Subject<CurrentUserStep> = new Subject<CurrentUserStep>();

  @Input() public currentRegisterJWTToken: string;

  @Input() isUserLoggingIn: boolean;

  @ViewChild('signupStepperOutletTemplate', { static: true, read: ViewContainerRef })
  private _signupStepperOutletTemplate: ViewContainerRef;

  private _injectedComponentRef: ComponentRef<any>;

  private _onDestroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private _router: Router) {}

  ngOnInit(): void {
    this._doInjectCurrentUserStep();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }

  private _doInjectCurrentUserStep(): void {
    this.currentUserStep$.pipe(distinctUntilChanged(), takeUntil(this._onDestroy$)).subscribe({
      next: (currentUserStep) => {
        this._signupStepperOutletTemplate.clear();
        if (this._injectedComponentRef) {
          this._injectedComponentRef.destroy();
        }
        switch (currentUserStep) {
          case 'signupLanding':
            this._doInjectSignupLandingComponent();
            break;
          case 'signupOtp':
            this._doInjectSignupOTPComponent();
            break;
          case 'signupDetails':
            this._doInjectSignupDetailsComponent();
            break;
        }
      },
    });
  }

  private _doInjectSignupLandingComponent(): void {
    this._createInjectedComponentRef(SignupLandingComponent);
    this._injectedComponentRef.changeDetectorRef.detectChanges();
  }

  private _doInjectSignupOTPComponent(): void {
    this._createInjectedComponentRef(SignupOTPComponent);
    this._injectedComponentRef.changeDetectorRef.detectChanges();
  }

  private _doInjectSignupDetailsComponent(): void {
    this._createInjectedComponentRef(SignupDetailsComponent);
    this._injectedComponentRef.instance.headerText = 'ساعدنا نعرفك اكثر';
    this._injectedComponentRef.changeDetectorRef.detectChanges();
  }

  private _createInjectedComponentRef(componentToInject: any): void {
    this._injectedComponentRef =
      this._signupStepperOutletTemplate.createComponent(componentToInject);
    this._injectedComponentRef.instance.registerStatusJWTToken = this.currentRegisterJWTToken;
    this._injectedComponentRef.instance.scope = 'signup';
    this._injectedComponentRef.instance.isUserLoggingIn = this.isUserLoggingIn;
  }
}
