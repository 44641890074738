import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { BaseComponent } from 'app/presentation/base/base.component';
import { SharedStepperIndicatorComponent } from '../../shared/components/shared-stepper-indicator/shared-stepper-indicator.component';
import { SignupStepperComponent } from './components/signup-stepper/signup-stepper.component';
import { SignUpPresenter } from './presenter/signup.presenter';
import { SignUpSideEffect } from './presenter/signup.sideEffects';
import { SignUpViewEvents } from './presenter/signup.viewEvents';
import { SignUpViewState } from './presenter/signup.viewState';

@Component({
  styleUrls: ['signup.component.scss'],
  templateUrl: 'signup.component.html',
  standalone: true,
  imports: [SharedStepperIndicatorComponent, SignupStepperComponent],
  providers: [SignUpPresenter, NgIf],
})
export class SignupComponent extends BaseComponent<
  SignUpPresenter,
  SignUpViewState,
  SignUpViewEvents,
  SignUpSideEffect
> {
  public presenter: SignUpPresenter = inject(SignUpPresenter);

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
    });
  }
}
