<div class="signUp-container">
  <loader [loading]="isLoading" size="lg"></loader>
  <app-shared-notification-component
    *ngIf="responseMessage"
    [config]="responseMessage"
  ></app-shared-notification-component>
  <div class="signUp-container__header">
    <img
      loading="lazy"
      class="signUp-container__header__img"
      src="{{ authAssetsRelativePath + 'signIcon.svg' }}"
      alt="sign icon"
    />
    <p class="signUp-container__header__text heading2--bold">
      أنشئ حساباً و ابدأ رحلة الأرباح مع تاجر
    </p>
  </div>
  <div class="signUp-container__form">
    <form [formGroup]="signupForm">
      <div class="signUp-container__form__form-field">
        <input
          class="form-control body2--medium"
          type="text"
          placeholder="البريد الالكترونى"
          formControlName="email"
        />
        <div *ngIf="email.invalid && email.touched">
          <div
            *ngIf="email.errors?.required || email.errors?.validEmail"
            class="signUp-container__form__form-field__error-container"
          >
            <img
              loading="lazy"
              class="signUp-container__form__form-field__danger"
              src="{{ authAssetsRelativePath + 'danger.svg' }}"
            />
            <span class="signUp-container__form__form-field__error-text caption1--regular"
              >البريد الالكتروني المدخل غير صحيح</span
            >
          </div>
        </div>
      </div>
      <div class="signUp-container__form__form-field">
        <app-meta-phone-number-field
          [showValidationErrorIndicator]="true"
          [removeUncoveredCountries]="true"
          [fieldIsValid]="signupForm.get('phoneNumber')?.valid"
          (result$)="phoneNumberChanges($event)"
        ></app-meta-phone-number-field>
      </div>
      <div class="signUp-container__form__form-field">
        <div class="signUp-container__form__form-field__password-container">
          <input
            class="form-control body2--medium"
            [type]="showPassword ? 'text' : 'password'"
            placeholder="كلمة المرور"
            formControlName="password"
          />
          <img
            loading="lazy"
            class="signUp-container__form__form-field__password-eye"
            src="{{
              showPassword
                ? authAssetsRelativePath + 'eye-slash.svg'
                : authAssetsRelativePath + 'eye.svg'
            }}"
            (click)="showHidePassword()"
          />
        </div>
        <div class="signUp-container__form__form-field__validation">
          <img
            loading="lazy"
            [src]="
              authAssetsRelativePath +
              (!password.dirty
                ? 'check-mark.svg'
                : password.errors?.minlength || password.errors?.required
                ? 'red-wrong.svg'
                : 'check-mark-success.svg')
            "
          />
          <span
            class="signUp-container__form__form-field__validation__text caption1--regular"
            [ngClass]="{
              'signUp-container__form__form-field__validation__text-error':
                (password.errors?.minlength || password.errors?.required) && password.dirty,
              'signUp-container__form__form-field__validation__text-success':
                !password.errors?.minlength && password.dirty && !password.errors?.required
            }"
            >يتكون من ٨ رموز على الاقل</span
          >
        </div>
        <div class="signUp-container__form__form-field__validation">
          <img
            loading="lazy"
            [src]="
              authAssetsRelativePath +
              (!password.dirty
                ? 'check-mark.svg'
                : password.errors?.containUpperAndLower || password.errors?.required
                ? 'red-wrong.svg'
                : 'check-mark-success.svg')
            "
          />
          <span
            class="signUp-container__form__form-field__validation__text caption1--regular"
            [ngClass]="{
              'signUp-container__form__form-field__validation__text-error':
                (password.errors?.containUpperAndLower || password.errors?.required) &&
                password.dirty,
              'signUp-container__form__form-field__validation__text-success':
                !password.errors?.containUpperAndLower &&
                password.dirty &&
                !password.errors?.required
            }"
            >يحتوي على احرف كبيرة و صغيرة</span
          >
        </div>
        <div class="signUp-container__form__form-field__validation">
          <img
            loading="lazy"
            [src]="
              authAssetsRelativePath +
              (!password.dirty
                ? 'check-mark.svg'
                : password.errors?.containNumAndChar || password.errors?.required
                ? 'red-wrong.svg'
                : 'check-mark-success.svg')
            "
          />
          <span
            class="signUp-container__form__form-field__validation__text caption1--regular"
            [ngClass]="{
              'signUp-container__form__form-field__validation__text-error':
                (password.errors?.containNumAndChar || password.errors?.required) && password.dirty,
              'signUp-container__form__form-field__validation__text-success':
                !password.errors?.containNumAndChar && password.dirty && !password.errors?.required
            }"
            >يحتوي على ارقام و رموز مثل @#&</span
          >
        </div>
      </div>
      <div class="signUp-container__form__form-field">
        <div class="signUp-container__form__form-field__password-container">
          <input
            class="form-control body2--medium"
            [type]="showRepeatedPassword ? 'text' : 'password'"
            placeholder="تأكيد كلمة المرور"
            formControlName="confirmPassword"
          />
          <img
            loading="lazy"
            class="signUp-container__form__form-field__password-eye"
            src="{{
              authAssetsRelativePath + (showRepeatedPassword ? 'eye-slash.svg' : 'eye.svg')
            }}"
            (click)="showHideRepeatedPassword()"
          />
        </div>
        <div
          *ngIf="signupForm.errors?.passwordNotMatch"
          class="signUp-container__form__form-field__error-container"
        >
          <img
            loading="lazy"
            class="signUp-container__form__form-field__danger"
            src="{{ authAssetsRelativePath + 'danger.svg' }}"
          />
          <span class="signUp-container__form__form-field__error-text caption1--regular"
            >كلمة المرور لا تتطابق</span
          >
        </div>
      </div>
      <div class="referral-code-register" *ngIf="isReferralProgramEnabled">
        <div class="referral-code-register__header">
          <div class="same-row">
            <img
              loading="lazy"
              src="{{ authAssetsRelativePath + 'envelope.svg' }}"
              alt="referral-icon"
            />
            <p class="body2--medium">هل تم دعوتك من خلال شخص ما؟</p>
          </div>
          <mat-slide-toggle
            class="signup-details-store-toggle"
            (change)="toggleReferralInput($event)"
            formControlName="referralSlideToggle"
          ></mat-slide-toggle>
        </div>
        <input
          *ngIf="hasReferralCode"
          placeholder="رمز الإحالة"
          type="text"
          formControlName="referralCode"
          class="form-control body2--medium"
        />
        <div class="same-row" *ngIf="isReferralCodeNotExist">
          <img src="{{ authAssetsRelativePath + 'red-wrong.svg' }}" />
          <p class="caption2--regular referral-code-register__error">
            الرمز غير متاح، تحقق من التاجر للحصول على رقم إحالته، وحاول مجدداً
          </p>
        </div>
      </div>
      <div *ngIf="isRecaptchaEnabled">
        <re-captcha #recaptcha (resolved)="onRecaptchaResponse($event)"></re-captcha>
        <br />
      </div>
      <button
        [disabled]="isSubmitButtonDisabled()"
        class="signUp-container__form__continue-button body2--medium"
        type="submit"
        (click)="onSubmit()"
      >
        استمرار
      </button>
      <p class="login-text body2--regular">
        لدي حساب بالفعل،
        <span class="login-link body2--bold" (click)="onGoToLoginClicked()"> تسجيل الدخول </span>
      </p>
    </form>
  </div>
</div>
