<div class="signup">
  <app-shared-stepper-indicator
    [allowedUserSteps]="viewState.allowedUserSteps"
    [currentUserStep$]="viewState.currentUserStep$"
    [isUserLoggingIn]="viewState.isUserLoggingIn"
  ></app-shared-stepper-indicator>
  <app-signup-stepper
    [currentRegisterJWTToken]="viewState.currentRegisterJWTToken"
    [currentUserStep$]="viewState.currentUserStep$"
    [isUserLoggingIn]="viewState.isUserLoggingIn"
  ></app-signup-stepper>
</div>
